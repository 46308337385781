import { useHTTPClient } from "helper";

const ValidationsService = () => {
  const { get, post } = useHTTPClient();

  const getValidationsDetails = (id, cancelToken) =>
    get(`Validations/Details?ValidationShortGuid=${id}`, cancelToken);

  const getAllValidations = () => get("Validations/GetAll");

  const getValidation = (shortGuid) => get(`/Validations?ShortGuid=${shortGuid}`);

  const exportValidations = (body) =>
    post("Validations/ExportValidation", body, { responseType: "blob" });

  const getAllProjectValidations = (projectShortGuid, cancelToken) =>
    get(`/Validations/GetAll?ProjectShortGuid=${projectShortGuid}`, cancelToken);

  return {
    getValidationsDetails,
    getAllValidations,
    getValidation,
    exportValidations,
    getAllProjectValidations,
  };
};

export default ValidationsService;
