import { useState } from "react";
import { DialogContent, Grid, InputLabel, Autocomplete, DialogActions } from "@mui/material";
import { AdjustableDialog, MDInput, MDTypography, MDButton, MDLoadingButton } from "components";
import { useTranslation, Trans } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { LookupService, ProjectsService } from "services";
import { useSnackbar } from "helper";

function ValidateProjectDialog({ open, onClose, project, updateValidationProjectData }) {
  const { t } = useTranslation();
  const {
    control,
    formState: { isValid },
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues: {
      projectShortGuid: project.shortGuid,
      disclosureSystem: project.disclosureSystem,
      calculationLinkbase: project.calculationLinkbase,
    },
    mode: "onChange",
  });
  const [disclosureSystems, setDisclosureSystems] = useState([]);
  const [isDisclosureSystemsLoading, setIsDisclosureSystemsLoading] = useState(false);
  const [calculationLinkbases, setCalculationLinkbases] = useState([]);
  const [isCalculationLinkbasesLoading, setIsCalculationLinkbasesLoading] = useState(false);
  const [isValidationInProgress, setIsValidationInProgress] = useState(false);
  const { getLookups } = LookupService();
  const { validateProject } = ProjectsService();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();

  const handleOnClose = () => {
    if (isValidationInProgress) return;

    setValue("projectShortGuid", project.shortGuid);
    setValue("disclosureSystem", project.disclosureSystem);
    setValue("calculationLinkbase", project.calculationLinkbase);
    onClose();
  };

  const fetchDisclosureSystems = async () => {
    try {
      if (disclosureSystems.length > 0) return;
      setIsDisclosureSystemsLoading(true);
      const { data, status } = await getLookups(["DisclosureSystems"]);
      if (status === 200) setDisclosureSystems(data.disclosureSystems);
    } catch (e) {
      showErrorSnackbar({ content: e?.response?.data?.errorTranslation || "apierrorfetch" });
    } finally {
      setIsDisclosureSystemsLoading(false);
    }
  };

  const fetchCalculationLinkbases = async () => {
    try {
      if (calculationLinkbases.length > 0) return;
      setIsCalculationLinkbasesLoading(true);
      const { data, status } = await getLookups(["CalculationLinkbases"]);
      if (status === 200) setCalculationLinkbases(data.calculationLinkbases);
    } catch (e) {
      showErrorSnackbar({ content: e?.response?.data?.errorTranslation || "apierrorfetch" });
    } finally {
      setIsCalculationLinkbasesLoading(false);
    }
  };

  const onSubmit = (event) => {
    event.preventDefault();
    return handleSubmit(
      async ({
        projectShortGuid,
        disclosureSystem: { shortGuid: disclosureSystemShortGuid },
        calculationLinkbase: { shortGuid: calculationLinkbaseShortGuid },
      }) => {
        try {
          setIsValidationInProgress(true);
          const {
            data: { disclosureSystem, calculationLinkbase },
            status,
          } = await validateProject({
            projectShortGuid,
            disclosureSystemShortGuid,
            calculationLinkbaseShortGuid,
          });
          if (status === 200) {
            updateValidationProjectData(disclosureSystem, calculationLinkbase);
            showSuccessSnackbar({
              content: "projects.details.validations.validationsucessfullystarted",
            });
          }
        } catch (e) {
          showErrorSnackbar({ content: e?.response?.data?.errorTranslation || "apierrorpost" });
        } finally {
          setIsValidationInProgress(false);
          onClose();
        }
      }
    )();
  };

  return (
    <AdjustableDialog
      dialogProps={{ open, onClose: handleOnClose }}
      paperStyleProps={{
        height: "auto",
        width: "55vw",
        minHeight: "25vh",
        minWidth: "50vw",
        maxWidth: "100vw",
      }}
      dialogTitleStyleProps={{ backgroundColor: "#fff" }}
      title={t("projects.details.validations.confirmrunvalidation")}
    >
      <DialogContent
        sx={{
          overflow: "hidden",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <Grid container>
          <Grid item xs={5.5}>
            <InputLabel sx={{ pb: 1 }}>
              {t("projects.details.validations.disclosuresystem")} *
            </InputLabel>
            <Controller
              name="disclosureSystem"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, ...otherProps } }) => (
                <Autocomplete
                  disableClearable
                  getOptionLabel={(option) => option.name || ""}
                  isOptionEqualToValue={(option, value) => option.shortGuid === value.shortGuid}
                  loading={isDisclosureSystemsLoading}
                  loadingText={t("autocomplete.loading")}
                  noOptionsText={t("autocomplete.nooptions")}
                  onChange={(_, value) => onChange(value)}
                  onOpen={fetchDisclosureSystems}
                  options={disclosureSystems}
                  renderInput={(params) => (
                    <MDInput
                      placeholder={t("projects.details.validations.disclosuresystemplaceholder")}
                      {...params}
                    />
                  )}
                  {...otherProps}
                />
              )}
            />
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={5.5}>
            <InputLabel sx={{ pb: 1 }}>
              {t("projects.details.validations.calculationslinkbase")} *
            </InputLabel>
            <Controller
              name="calculationLinkbase"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, ...otherProps } }) => (
                <Autocomplete
                  disableClearable
                  getOptionLabel={(option) => option.name || ""}
                  isOptionEqualToValue={(option, value) => option.shortGuid === value.shortGuid}
                  loading={isCalculationLinkbasesLoading}
                  loadingText={t("autocomplete.loading")}
                  noOptionsText={t("autocomplete.nooptions")}
                  onChange={(_, value) => onChange(value)}
                  onOpen={fetchCalculationLinkbases}
                  options={calculationLinkbases}
                  renderInput={(params) => (
                    <MDInput
                      placeholder={t(
                        "projects.details.validations.calculationslinkbaseplaceholder"
                      )}
                      {...params}
                    />
                  )}
                  {...otherProps}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sx={{ pt: 2, textAlign: "center" }}>
            <MDTypography>
              <Trans
                i18nKey="projects.details.validations.confirmrunvalidationcontent"
                tOptions={{ projectName: project.name }}
                components={[<b />]}
              />
            </MDTypography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container>
          <Grid item xs={7.5} />
          <Grid item xs={2}>
            <MDButton
              fullWidth
              color="dark"
              variant="outlined"
              onClick={handleOnClose}
              disabled={isValidationInProgress}
            >
              {t("cancel")}
            </MDButton>
          </Grid>
          <Grid item xs={0.5} />
          <Grid item xs={2}>
            <MDLoadingButton
              fullWidth
              color="dark"
              variant="gradient"
              onClick={onSubmit}
              isLoading={isValidationInProgress}
              disabled={!isValid}
            >
              {t("confirm")}
            </MDLoadingButton>
          </Grid>
        </Grid>
      </DialogActions>
    </AdjustableDialog>
  );
}

export default ValidateProjectDialog;
